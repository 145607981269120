<template>
  <div id="nav" v-if="!isMobile" :class="['navigation', isScrolled  ? 'navigation--scrolled' : '']">
    <div class="navigation__content v-row">
      <router-link :to="{ name: 'main' }">
        <div class="navigation__logo-plug">
          <img class="navigation__logo" :onload="defaultImage" src="../assets/logo.png" />
        </div>
      </router-link>
      <div class="navigation__items v-row">
        <router-link :to="{ name: 'main' }">
          <p 
            :class="{ active: $route.name === 'main' }"
            class="navigation__item"
          >
            Главная
          </p>
        </router-link>
        <router-link :to="{ name: 'map' }">
          <p 
            :class="{ active: $route.name === 'map' }"
            class="navigation__item"
          >
            Карта
          </p>
        </router-link>
        <router-link :to="{ name: 'about' }">
          <p 
            :class="{ active: $route.name === 'about' }"
            class="navigation__item"
          >
            О нас
          </p>
        </router-link>
      </div>
      <div class="navigation__item-wrapper">
        <p class="navigation__item navigation__item--user" @click="openDropdown">{{ user ? user.name : ''}}</p>
      </div>
      <div v-if="isDropdown" class="nav__dropdown">
        <ul>
          <li @click="logOut">
            Выход
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="mobile-nav" v-else>
    <div class="mobile-nav__content v-row">
      <router-link :to="{ name: 'main' }">
        <div class="navigation__logo-plug">
          <img class="navigation__logo" :onload="defaultImage" src="../assets/logo.png" />
        </div>
      </router-link>
      <div class="close" @click="openDropdown">
          <div class="open__button" v-if="!isDropdown">
              <div class="straight-line" />
              <div class="straight-line" />
          </div>
          <div class="close__button" v-else>
              <div class="first-line" />
              <div class="second-line" />
          </div>
      </div>
      <div v-if="isDropdown" class="mobile-nav__dropdown">
        <ul>
          <li :class="{ active: $route.name === 'main' }" @click="goTo('main')">
            Главная
          </li>
          <li :class="{ active: $route.name === 'map' }" @click="goTo('map')">
            Карта
          </li>
          <li :class="{ active: $route.name === 'about' }" @click="goTo('about')">
            О нас
          </li>
          <li @click="logOut">
            Выход
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import img from '@/assets/bg.png'

export default {
  data: () => ({
    isScrolled: false,
    isDropdown: false,
  }),
  computed: mapGetters(['userLoading', 'user']),
  mounted() {
    window.document.onscroll = () => {
      var navbar = document.getElementById('nav');
      let scroll = window.scrollY
      if (scroll > navbar.offsetTop) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    }
  },
  methods: {
    ...mapActions(["logout"]),
    defaultImage(e) {
      e.target.src = img
    },
    openDropdown() {
      this.isDropdown = !this.isDropdown
    },
    goTo(name) {
      if (this.$route.name !== name) {
        this.$router.push({ name: name })
        this.isDropdown = false;
      } 
    },
    logOut() {
      this.$router.push({ name: 'login' })
      setTimeout(() => {
          this.logout();
      }, 5000)

    },
  }
};
</script>

<style  lang="scss" scoped>
.v-row {
  display: flex;
}

.mobile-nav {
  width: 100% !important;
  box-sizing: border-box;
  height: 70px;
  padding: 10px 20px;
  z-index: 33;
  position: absolute;
  top:0;
  background-color: #445f7a;
  &--hidden {
    z-index: 2 !important;
  }
  &__content {
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;
  }
  &__item {
    width: fit-content;
    text-decoration: none;
    color: rgb(250, 204, 0);
    display: flex;
    align-items: center;
    transition: 0.1s ease-in-out;
  }
  &__dropdown {
    text-decoration: none;
    position: absolute;
    right: 20px;
    top: 50px;
    border-radius: 3px;
    width: fit-content;
    background: #445f7a;
    border: 1px solid white;
    display: flex;
    justify-content: center;
  }
}

.nav__dropdown {
  text-decoration: none;
  position: absolute;
  right: calc((100vw - 1200px) / 2);
  top: 50px;
  border-radius: 3px;
  width: fit-content;
  background: #445f7a;
  border: 1px solid white;
  display: flex;
  justify-content: center;
}

ul {
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  padding-inline-start: 0px !important;
  box-sizing: border-box;
  list-style-type: none;
}

li {
  color: white;
  font: 14px proxima-regular;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-bottom: 1px solid #577292a1;
}

.navigation {
  width: 100%;
  height: 50px;
  transition: 0.6s;
  padding: 10px 0;
  background-color: transparent;
  justify-content: space-between;
  z-index: 33;
  position: fixed;
  background-color: #445f7a;
  &__content {
    width: 1200px;
    justify-content: space-between;
    margin: 0 auto;
  }
  &__logo {
    margin-top: -4px;
    display: inline-block;
    height: 50px;
    image-rendering: -webkit-optimize-contrast;
    &-plug {
      margin-top: -4px;
      width: 114px;
    }
  }
  &__items {
    margin-top: 0px;
    margin-left: -20px;
    align-items: center;
    justify-content: space-between;
    width: 30%;
  }
  &__item {
    display: inline-block;
    width: fit-content;
    padding: 0 10px;
    text-decoration: none;
    color: white;
    font: proxima-regular;
    font-size: 15px;
    font-weight: 500;
    transition: 0.1s ease-in-out;
    &--user {
      color:rgb(255, 208, 0) !important;
      font-weight: 600 !important;
      margin-left: auto;
      margin-top: 17px;
    }
    &-wrapper {
      width: 100px;
      display: flex;
      justify-content: flex-end;
    }
    &:hover {
      cursor: pointer;
      color: rgb(255, 208, 0);
      font-weight: 500;
    }
  }
  &--scrolled {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
  }
}

.active {
  color: rgb(255, 208, 0);
}

.close {
    flex: 1 0 auto;
    max-width: 20px;
    height: 20px;
    margin: auto 0 auto auto;
    overflow: hidden;
    &__button {
      cursor: pointer;
      transform: rotate(45deg);
    }
}
.straight-line {
  position: relative;
  width: 20px;
  height: 2px;
  background: #b4b8bd;
  margin-bottom: 5px;
  top: 6px;
}
.first-line {
  position: relative;
  width: 20px;
  height: 2px;
  top: 9px;
  background: #b4b8bd;
}
.second-line {
  position: relative;
  width: 2px;
  height: 20px;
  top: -2px;
  left: 9px;
  background: #b4b8bd;
}

</style>

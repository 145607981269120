import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import VModal from 'vue-js-modal';
import setAuthToken from './utils/setAuthToken';
import Auth from './mixins/auth';
import Filters from './mixins/filters';
import Device from './mixins/device';

Vue.mixin(Auth); 
Vue.mixin(Filters);
Vue.mixin(Device);  

setAuthToken(localStorage.token);

Vue.use(VModal);
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    computed: {
        ...mapGetters(['loading', 'homes', 'mapHomes', 'filters', 'favs', 'appLoading']),
        filter: {
            get() { 
                return this.filters 
            },
            set(key, value) { 
                this.setFilters(key, value) 
            }
        },
    },
    methods: {
        ...mapActions(['getUser','fetchHomes', 'fetchFavs', 'addFav', 'removeFav']),
        ...mapMutations(['setDefaultFilters', 'setFilters', 'setPage']),
        parseQuery() {
            this.setDefaultFilters()
            const query = this.$route.query;
            if (query.page) this.setPage(query.page)
            Object.keys(query).forEach(key => {
                const value = query[key]
                if (key === 'favs') {
                    this.filter[key] = value === 'true' ? true : false
                    return
                }
                if (this.filter[key] instanceof Array) {
                    this.filter[key] = value.split(',')
                    return
                }
                this.filter[key] = value
            })
        },
        formQuery() {
            const filter = this.filter;
            const result = {}
            Object.keys(filter).forEach(key => {
                const value = filter[key]
                if (!value || value.length === 0) return
                if (value instanceof Array) {
                    value.sort((a, b) => a - b);
                    result[key] = String(value.join(','))
                    return
                }
                result[key] = value
                delete result.page
            })
            return result;
        },
        paramsSubmit(from) {
            if (this.$route.query !== this.formQuery()) {
                if (this.$route.name !== 'map') {
                    this.$router.replace({ query: this.formQuery()})
                    if (from === 'filter') window.scrollTo({ top: 400, behavior: 'smooth' });
                    return;
                }
                this.mapSubmit()
            }
        },
        mapSubmit() {
            this.$router.push({ name: 'map', query: this.formQuery()})
        },
        resetFilters() {
            this.$router.replace({ query: {} }).catch(()=>{});
        },
    }
};

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/homes',
            name: 'main',
            component: () => import('../views/Main.vue'),
        },
        {
            path: '/homes/:id',
            name: 'home',
            component: () => import('../views/Home.vue'),
        },
        {
            path: '/map',
            name: 'map',
            component: () => import('../views/Map.vue'),
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('../views/About.vue'),
        }, 
        {
            path: '/login',
            name: 'login',
            meta: { withoutNav: true },
            component: () => import('../views/Login.vue'),
        }, 
        {
            path: '*',
            redirect: '/homes',
        }, 
   ],
   
    scrollBehavior(to, from) {
        if (to.name === from.name) return
    },
})

router.beforeEach(async (to, from, next) => {
    const store = await import('@/store');
    await store.default.commit('appLoad')
    await store.default.dispatch('getUser')
    if (await store.default.getters.authenticate || to.name === 'login') {
        return next()
    }
    next({ name: 'login'})
})

export default router;
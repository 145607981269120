import Vuex from 'vuex'
import Vue from 'vue'
import homes from './modules/homes'
import auth from './modules/auth'

// Load Vuex
Vue.use(Vuex)

// create store
export default new Vuex.Store({
    modules: {
        homes,
        auth
    }
})
import axios from 'axios'
import { url, headers } from '../../utils/config'
import setAuthToken from '../../utils/setAuthToken'

const state = {
    authenticate: false,
    user: null,
    message: null,
    loading: false,
    appLoading: false,
}

const getters = {
    message: state => state.message,
    user: state => state.user,
    userLoading: state => state.loading,
    authenticate: state => state.authenticate,
    appLoading: state => state.appLoading,
}

const actions = {
    async register({commit}, data) {
        try {
            const res = await axios.post(`${url}/user`, data, {headers})
            commit('authSuccess', res.data)
        } catch (err) {
            commit('authError', err.response.data)
        }
    },
    async login({commit}, data) {
        try {
            const res = await axios.post(`${url}/auth`, data, {headers})
            commit('authSuccess', res.data)
        } catch (err) {
            commit('authError', err.response.data)
        }
    },
    async getUser({commit}) {
        try {
            state.loading = true
            const res = await axios.get(`${url}/auth/user`, {headers})
            commit('userSuccess', res.data)
        } 
        catch (err) {
            // eslint-disable-next-line no-console
            commit('authError', 'Время сессии истекло')
        }
        finally {
            commit('appUnload')
        }
    },
    async logout({
        commit
    }) {
        commit('logout')
    }
}

const mutations = {
    appLoad: (state) => (state.appLoading = true),
	appUnload: (state) => (state.appLoading = false),
    userSuccess: (state, response) => {
        state.user = response
        state.authenticate = true
        state.loading = false
        state.message = null
    },
    userError: (state, response) => {
        state.message = response.msg
        state.authenticate = false
        state.loading = false
        state.user = null
    },
    authSuccess: (state, response) => {
        state.user = response.user
        state.authenticate = true
        state.message = null
        let tokenObj = {
            value: response.token,
            expiresIn: new Date(new Date().getTime() + (23 * 60 * 60 * 1000)).getTime()
        }
        tokenObj = JSON.stringify(tokenObj);
        localStorage.setItem('token', tokenObj);
        setAuthToken(localStorage.token)
    },
    authError: (state, response) => {
        state.message = response.msg
        state.authenticate = false
        state.user = null
    },
    logout: (state) => {
        state.authenticate = false
        state.user = null
        localStorage.removeItem('token');
        setAuthToken(false)
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
export default {
    computed: {
        isLogin() {
            let tokenObj = localStorage.getItem('token');
            if (tokenObj) {
                tokenObj = JSON.parse(tokenObj);
                if (!(new Date().getTime() > parseInt(tokenObj.expiresIn))) {
                    return true;
                }
            }
            return false;
        }
    }
};

<template>
    <div id="app" class="app">
        <preloader class="app__preloader" v-if="appLoading && !user" />
        <template v-else>
            <navigation v-if="!isWithoutNav && user" />
            <router-view style="flex: 1 0 auto;" />
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Preloader from '@/components/Preloader'
import Navigation from "@/components/Navigation";

export default {
    components: {
        Navigation, Preloader,
    },
    computed: {
        ...mapGetters(['user']),
        isWithoutNav() {
            return this.$route.meta.withoutNav;
        }
    },
}
</script>

<style>
@font-face {
    font-family: proxima-bold;
    src: url(assets/fonts/ProximaNovaBold.woff);
}
@font-face {
    font-family: proxima-regular;
    src: url(assets/fonts/ProximaNovaRegular.woff);
}
@font-face {
    font-family: proxima-light;
    src: url(assets/fonts/ProximaNovaLight.woff);
}
input::-webkit-input-placeholder {color: rgba(180, 180, 180, 1);}
input:-moz-placeholder {color: rgba(180, 180, 180, 1);}
input:-ms-input-placeholder {color: rgba(180, 180, 180, 1);}
input[type='checkbox'] {
    cursor:pointer !important;
}

html {
    height: 100%;
    max-width: 100%;
    width: 100%;
    margin: 0;
    box-sizing:border-box;
    overflow-x: hidden;
    overflow-y:scroll;
}
body {
    margin: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
    color: rgb(15, 42, 72);
    box-sizing:border-box;
}

a, button, input {
    text-decoration: none;
    outline: none;
}

sup {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
}
h1 {
    font: 28px proxima-bold;
    border: 0;
    margin: 0;
}
h2 {
    font: 24px proxima-bold;
    border: 0;
    margin: 0;
}
h3 {
    font: 20px proxima-bold;
    border: 0;
    margin: 0;
}

p {
    font: 14px proxima-regular;
    border: 0;
    margin: 0;
}

#nav {
    font: 24px proxima-regular;
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
.mobile__content {
    padding: 0px 20px;
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
}

.content {
    margin: 0 auto 70px auto;
    padding: 0 20px;
    max-width: 100%;
    min-width: 1200px;
    display: flex;
    flex-wrap: wrap;
}
.content__570 {
    flex: 1 1 0;
    max-width: 567px;
    min-width: 567px;
    margin-right: 33px;
}
.content__600 {
    flex: 1 1 0;
    max-width: 600px;
    min-width: 600px;
}
.content__980 {
    flex: 1 1 0;
    max-width: 980px;
    min-width: 980px;
}
.content__170 {
    flex: 1 1 0;
    max-width: 170px;
    min-width: 170px;
    margin-right: 50px;
}
.content__760 {
    flex: 1 1 0;
    max-width: 760px;
    margin-right: 100px;
}
.content__340 {
    flex: 1 1 0;
    max-width: 340px;
}
.content__800 {
    flex: 1 1 0;
    max-width: 800px;
    min-width: 800px;
}

.app__preloader {
    margin: auto;
    z-index: 1000;
}

.text-m {
    font: 14px proxima-regular;
    letter-spacing: 0.01em;
}

.text-bold {
    font: 14px proxima-bold;
    letter-spacing: 0.01em;
}

.text-s {
    font: 13px proxima-regular;
    letter-spacing: 0.01em;
}
.text-s-bold {
    font: 13px proxima-bold;
    letter-spacing: 0.01em;
}

.gray {
    color: rgb(140, 140, 140);
}
.blue {
    color: rgb(53, 121, 180) !important;
}
.light_blue {
    color: rgb(154, 182, 221);
}
.gray-ultra {
    color: rgb(180, 180, 180);
}

.link {
    color: rgb(15, 42, 72);
    cursor: pointer;
}
.link:hover {
    color: rgb(180, 180, 180);
}

.link_gray {
    color: rgb(180, 180, 180);
    cursor: pointer;
}
.link_gray:hover {
    color: rgb(15, 42, 72);
}

.link_ultra-blue {
    color: rgb(15, 42, 72);
    cursor: pointer;
}
.link_ultra-blue:hover {
    color: rgb(250, 82, 50);
}

.link_white {
    color: white;
    cursor: pointer;
}
.link_white:hover {
    color: rgb(250, 82, 50);
}


/* Тип квартир, тип отделки и т.д. */
.check-group {
    display: flex;
}
.check-group div:first-of-type label {
    border-radius: 3px 0 0 3px;
}
.check-group div:last-of-type label {
    border-radius: 0 3px 3px 0;
}
.check-group input {
    position: absolute;
    z-index: -50;
}
.check-group label {
    display: block;
    margin: 0 0 0 -1px;
    padding: 0 20px;
    height: 26px;
    background: white;
    border: 1px solid rgb(220, 220, 220);
    cursor: pointer;
    font: 13px proxima-regular;
    line-height: 27px;
}
.check-group input:checked + label {
    position: relative;
    background: rgb(255, 235, 160);
    border: 1px solid rgb(204, 188, 127);
    z-index: 2;
}
.check-group input:checked + label:hover {
    background: rgb(255, 235, 160);
    border: 1px solid rgb(204, 188, 127);
}
.check-group input + label:hover {
    background: rgb(255, 247, 215);
    border: 1px solid rgb(230, 222, 191);
    position: relative;
    z-index: 1;
}


/* Инпуты от-до */
.from-to-group div {
    display: flex;
}
.from-to-group div input:first-of-type {
    border-radius: 3px 0 0 3px;
}
.from-to-group div input:last-of-type {
    border-radius: 0 3px 3px 0;
}
.from-to-group input {
    position: relative;
    margin: 0 0 0 -1px;
    height: 26px;
    padding: 0;
    border: 1px solid rgb(220, 220, 220);
    font: 13px proxima-regular;
    /*line-height: 27px;*/
    line-height: 0;
    text-indent: 12px;
    width: 80px;
}
.mobile-from-to-group input {
    width: 75px;
}
.from-to-group--long input {
    position: relative;
    margin: 0 0 0 -1px;
    height: 26px;
    padding: 0;
    border: 1px solid rgb(220, 220, 220);
    font: 13px proxima-regular;
    /*line-height: 27px;*/
    line-height: 0;
    text-indent: 12px;
    width: 130px;
}
.from-to-group input:hover {
    border: 1px solid rgb(200, 200, 200);
    z-index: 1;
}
.from-to-group input:focus {
    border: 1px solid rgb(180, 180, 180);
    z-index: 2;
}


/* Инпут для фильтра */
.text-input div {
    display: flex;
}
.text-input div input {
    margin: 0;
    height: 26px;
    width: 160px;
    padding: 0;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 3px;
    font: 13px proxima-regular;
    line-height: 0;
    text-indent: 12px;
}
.text-input--long div input {
    margin: 0;
    height: 26px;
    width: 200px;
    padding: 0;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 3px;
    font: 13px proxima-regular;
    line-height: 0;
    text-indent: 12px;
}
.text-input input:hover {
    border: 1px solid rgb(200, 200, 200);
}
.text-input input:focus {
    border: 1px solid rgb(180, 180, 180);
}


/* Кнопка сабмита формы */
.submit-button {
    border: 0;
    height: 32px;
    padding: 0 25px;
    border-radius: 60px;
    font: 13px proxima-regular;
    background: rgba(255, 219, 77, 1);
    cursor: pointer;
}
.submit-button:hover {
    background: rgba(255, 226, 111, 1);
}

.url {
    text-decoration: none;
    color: rgb(71, 126, 173);
}
</style>

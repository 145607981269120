export default {
    data() {
        return {
            UA: window.navigator.userAgent,
        }
    },
    computed: {
        isMobile() {
            return /iPhone|iPad|iPod|Android|Windows Phone/.test(this.UA);
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    methods: {
        onResize() {
            this.UA = window.navigator.userAgent;
        },
    }
}
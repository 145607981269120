<template>
  <div class="preloader">
    <img
      src="../assets/preloader.png"
      alt="Загрузка..."
      class="preloader__img"
    >
  </div>
</template>

<script>
export default {
  name: 'preloader',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes preloader-spin {
  100% {
    transform:rotate(360deg);
  }
}

.preloader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__img {
    width: 60px;
    height: 60px;
    animation: preloader-spin 2s linear infinite;
  }
}
</style>

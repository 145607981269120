import axios from 'axios';
import {url} from '../../utils/config';

const state = {
	homes: [],
	mapHomes: [],
	clusterHomes: [],
	favs: [],
	pages: {},
	home: {},
	count: {},
	filters: {},
	loading: false,
	objLoading: false,
	clusterLoading: false,
};

const getters = {
	homes: state => state.homes,
	mapHomes: state => state.mapHomes,
	clusterHomes: state => state.clusterHomes,
	count: state => state.count,
	pages: state => state.pages,
	filters: state => state.filters,
	loading: state => state.loading,
	objLoading: state => state.objLoading,
	clusterLoading: state => state.clusterLoading,
	favs: state => state.favs,
	home: state => state.home,
};

const actions = {
	async fetchHomes({commit}, data) {
		commit('setLoading', true);
		const filter = {}
		Object.keys(state.filters).forEach(key => {
			const value = state.filters[key]
			if (key === 'favs') {
				filter[key] = value
				return
			}
			if (key === 'sort') {
				filter.sort = Number(value)
				return
			}
			if (!value || value.length === 0) return
			if (value instanceof Array) {
				filter[key] = value
				return
			}
			if (key === 'title' || key === 'address') {
				filter[key] = String(value)
				return
			}
			filter[key] = Number(value)
		})
		const response = await axios.post(`${url}/homes`, { ...filter, page: state.pages.current, ...data });
		if (data.map) {
			commit('clearMapHomes');
			commit('setMapHomes', response.data.items);
		}
		if (!data.map) {
			commit('clearHomes');
			commit('setHomes', response.data.items);
		}
		commit('setPages', response.data.pages);
		commit('setCount', response.data.count);
		commit('setLoading', false);
	},
	async fetchClusterHomes({commit}, ids) {
		commit('setClusterLoading', true);
		const response = await axios.post(`${url}/homes/cluster`, { ids: ids });
		commit('clearClusterHomes');
		commit('setClusterHomes', response.data.items);
		commit('setClusterLoading', false);
	},
	async fetchHome({commit}, id) {
		commit('clearHome');
		commit('setLoading', true);
		const response = await axios.get(`${url}/homes/${id}`);
		commit('setHome', response.data);
		commit('setLoading', false);
	},
	async fetchMapHome({commit}, id) {
		commit('clearHome');
		commit('setObjLoading', true);
		const response = await axios.get(`${url}/homes/${id}`);
		commit('setHome', response.data);
		commit('setObjLoading', false);
	},

	async fetchFavs({commit}) {
		commit('clearFavs');
		const response = await axios.get(`${url}/favs`, {});
		commit('setFavs', response.data);
	},

	async addFav({commit}, id) {
		commit('clearFavs');
		const response = await axios.put(`${url}/favs/add`, {id});
		commit('setFavs', response.data);
	},

	async removeFav({commit}, id) {
		commit('clearFavs');
		const response = await axios.delete(`${url}/favs/remove/${id}`);
		commit('setFavs', response.data);
	},
};

const mutations = {
	setLoading: (state, status) => (state.loading = status),
	setObjLoading: (state, status) => (state.objLoading = status),
	setClusterLoading: (state, status) => (state.clusterLoading = status),
	setHomes: (state, homes) => (state.homes = homes),
	setClusterHomes: (state, homes) => (state.clusterHomes = homes),
	setPages: (state, pages) => (state.pages = pages),
	setFavs: (state, favs) => (state.favs = favs),
	clearFavs: (state) => {
		state.favs.length = 0;
	},
	removeFav: (state, favs) => (state.favs = favs),
	clearHomes: (state) => {
		state.homes.length = 0;
	},
	clearMapHomes: (state) => {
		state.mapHomes.length = 0;
	},
	clearClusterHomes: (state) => {
		state.clusterHomes.length = 0;
	},
	clearHome: (state) => {
		state.home = {}
	},
	setHome: (state, home) => (state.home = home),
	setFilters: (state, key, value) => {
		state.filters[key] = value;
	},
	setPage: (state, value) => {
		state.pages.current = value;
	},
	setCount: (state, value) => {
		state.count = value
	},
	setMapHomes: (state, homes) => {
		const result = []
		homes.forEach(home => {
			result.push({
					type: 'Feature',
					options: {
						iconColor: localStorage.getItem('ymap_ids') && localStorage.getItem('ymap_ids').includes(home.id)
									? '#75a3bd'
									: '#3F709B', 
						preset: 'islands#nightCircleDotIcon'
					},
					id: home.id,
					geometry: {
						type: 'Point',
						coordinates: [home.coords.lat, home.coords.lng],
					}
			})
		})
		state.mapHomes = result;
	},
	setDefaultFilters: (state) => {
		state.filters = {
			price1: '',
			price2: '',
			title: '',
			type: [],
			rooms: [],
			category: [],
			district: [],
			street: '', 
			sizes_full1: '',
			sizes_full2: '',
			full_floor1: '',
			full_floor2: '',
			rent_time: [],
			ready: [],
			floor1: '',
			floor2: '',
			favs: false,
			photo: [],
			comission: [],
			person: [],
			material: [],
			sub_category: [],
			sort: -2,
		};
		state.pages.current = 1
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};